.modalNavBar {
  position: fixed;
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(
    90deg,
    hsl(0deg 0% 0%) 0%,
    hsl(344deg 0% 3%) 30%,
    hsl(344deg 0% 6%) 46%,
    hsl(344deg 0% 8%) 56%,
    hsl(344deg 0% 10%) 65%,
    hsl(344deg 0% 12%) 72%,
    hsl(344deg 0% 14%) 78%,
    hsl(344deg 0% 16%) 84%,
    hsl(344deg 0% 18%) 91%,
    hsl(0deg 0% 20%) 100%
  );
  z-index: 2;
  right: -100%;
  max-width: 100%;
  transition: 400ms;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-content: center;
}

.modalNavBarContent {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
  max-width: 100%;
}

.modalNavBarContent ul {
  padding: 0;
}

.modalNavBarContent ul li {
  list-style: none;
}

.modalNavBarContent ul li p {
  color: white;
  font-family: "League Spartan", sans-serif;
  font-weight: 700;
  font-size: clamp(22px, 1.5vw, 30px);
  transition: color 300ms;
  cursor: pointer;
  padding: 0;
  font-size: 2rem;
}

.modalNavBarContent ul li p:hover {
  color: rgb(255, 113, 91);
}

.modalNavBar .bannerMediaLinks {
  width: 300px;
  margin-bottom: 5vh;
}

.hamburger {
  z-index: 2;
  position: absolute;
  top: 30px;
  right: 40px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: white;
  transition: 200ms;
}

.hamburger:hover {
  color: rgb(255, 113, 91);
  transform: scale(1.05);
}

.red {
  color: rgb(255, 113, 91) !important;
}

.red:hover {
  color: white !important;
}

.show {
  right: 0;
}
