#footerContainer {
  background-color: #1c1d25;
  padding: 30px 0 25px 0;
  color: white;
  text-align: center;
}

#footerContainer img {
  width: 50px;
  height: 50px;
}

.footerIcon {
  width: 30px;
  height: 30px;
  margin: 0 10px;
  cursor: pointer;
  color: rgb(255, 113, 91);
  transition: 200ms;
}

.footerIcon:hover {
  color: white;
}

#footerContainer p {
  font-size: clamp(13px, 1vw, 16px);
}
