.custom-shape-divider-top-1668698050 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  opacity: 0.1;
  line-height: 0;
}

.custom-shape-divider-top-1668698050 svg {
  position: relative;
  display: block;
  width: calc(200%);
  height: 200px;
  transform: rotateY(180deg);
}

.custom-shape-divider-top-1668698050 .shape-fill {
  fill: #ffffff6e;
}
