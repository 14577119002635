.aboutContainerWraper {
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  animation: backgroundAppear 1s;
  overflow: hidden;
}

.backgroundOut {
  animation: backgroundDisappear 600ms;
}

@keyframes backgroundAppear {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(0, 0, 0, 0.6);
  }
}

@keyframes backgroundDisappear {
  from {
    background-color: rgba(0, 0, 0, 0.6);
  }
  to {
    background-color: transparent;
  }
}

.aboutContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: fixed;
  width: 1170px;
  height: 80vh;
  z-index: 10;
  top: 50%;
  margin-left: auto;
  margin-top: -40vh;
  text-align: left;
}

.outInfoAnimation {
  transition: 600ms;
  translate: -100% 0;
  animation: dis 400ms forwards !important;
}

.outFormAnimation {
  transition: 600ms;
  translate: 100% 0;
  animation: dis 400ms forwards !important;
}

@keyframes dis {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.aboutInfo {
  background-color: hsla(0, 0%, 95%, 0.97);
  height: 100%;
  width: 100%;
  position: relative;
  animation: aboutAppearUp 900ms ease;
  opacity: 1;
  color: #1c1d25;
  background-size: cover;
}

@keyframes aboutAppearUp {
  from {
    opacity: 0;
    top: -100%;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

.aboutForm {
  background-color: rgba(28, 29, 37, 0.985);
  height: 100%;
  width: 100%;
  position: relative;
  animation: aboutAppear 900ms ease forwards;
  animation-delay: 250ms;
  opacity: 0;
  color: #dadada;
}

@keyframes aboutAppear {
  from {
    opacity: 0;
    top: 100%;
  }
  to {
    opacity: 1;
    top: 0;
  }
}

.aboutInfo .aboutText {
  margin: 50px;
}

.aboutForm div {
  margin: 50px;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin: 20px 0;
}

input,
textarea {
  background-color: transparent !important;
  border: none;
  border-bottom: solid 3px silver;
  color: silver;
  background-clip: text !important;
  transition: 500ms;
}

textarea:focus,
input:focus {
  outline: none;
  border-bottom: solid 3px #8e443d;
}

input:-webkit-autofill {
  -webkit-text-fill-color: silver;
}

input:-webkit-autofill:focus {
  -webkit-text-fill-color: silver;
}

#submitButton {
  -webkit-background-clip: unset !important;
  border: none;
  background-image: linear-gradient(90deg, rgb(252, 76, 49), rgb(255, 176, 91));
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 20px;
  transition: 400ms;
  transform: scale(1.05);
  cursor: pointer;
  margin: 40px auto;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
  width: min-content;
}

#submitButton:hover {
  transform: scale(1);
  translate: 0;
  box-shadow: 0 0 10px 0 rgba(253, 149, 107, 0.3);
}

.closeAbout {
  position: absolute;
  right: 25px;
  top: 0;
  cursor: pointer;
  font-weight: bolder;
  color: silver;
  transition: 250ms ease-in-out;
  font-size: 2rem;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
}

.closeAbout:hover {
  color: rgb(252, 76, 49);
  transform: rotate(180deg);
}

.formSubtitlte {
  font-family: "League Spartan", sans-serif;
  font-size: 400;
}

@media (max-width: 1200px) {
  .aboutContainer {
    grid-template-columns: 1fr;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    margin: 0;
    top: 0;
  }

  .aboutInfo {
    height: 500px;
  }
}

textarea {
  height: 80px;
}
