@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;900&family=Montserrat:wght@300;400;900&display=swap");

.home {
  max-width: 100%;
  text-align: center;
  background-size: 100%;
  font-family: "Montserrat", sans-serif;
  text-rendering: optimizeLegibility;
}

.noScroll {
  overflow: hidden;
}

a:link,
a:visited,
a:active {
  text-decoration: none;
}
