#projectsContainer {
  /* background-color: rgb(241, 255, 255); */
  justify-content: center;
  padding-bottom: 200px;
}

.projectsProjectsContainer {
  display: grid;
  justify-content: center;
  gap: 100px;
}

.projectsProject {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 950px;
  gap: 20px;
}

.projectDescription {
  text-align: left;
}

.projectDescription h1 {
  margin-top: 0;
  margin-bottom: 0;
}

.projectDescription h2 {
  margin-top: 0;
  margin-bottom: 0;
  color: rgb(96, 96, 96);
  -webkit-text-stroke: 1px rgba(201, 200, 200, 0.407);
}

.projectDescriptionButton {
  width: fit-content;
  border-radius: 5px;
  margin-left: 20px;
  margin-top: 20px;
}

.projectDescriptionButton button {
  border: none;
  border-radius: 5px;
  background-color: rgb(255, 113, 91);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding: 15px 30px;
  transition: 400ms;
  cursor: pointer;
}

.projectDescriptionButton button:hover {
  transform: scale(1.05);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
}

.projectTool {
  margin: 15px 0 35px;
  color: white;
  padding: 5px 10px;
  background-color: black;
  border-radius: 5px;
  width: min-content;
  cursor: pointer;
}

.projectTool p {
  margin: 0;
}

.projectsSnippet {
  width: 500px;
  border-radius: 8px;
}

.targetGym {
  background-color: orangered;
}

.projectsSnippet img {
  width: 100%;
}

@media (max-width: 800px) {
  .projectsProject {
    grid-template-columns: 1fr;
    width: 95%;
  }

  .projectDescription {
    text-align: center;
    margin: auto;
    width: 95%;
  }

  .projectDescriptionButton {
    margin: auto;
  }

  .projectTool {
    margin: auto;
  }

  .projectsSnippet {
    margin: auto;
    width: 95%;
    order: -1;
  }
}
