#bannerContainer {
  display: flex;
  height: 100vh;
  color: white;
  background-image: linear-gradient(
    35deg,
    rgb(0, 0, 0) 5%,
    hsl(261deg 80% 11%) 30%,
    hsl(257deg 72% 13%) 40%,
    hsl(257deg 72% 16%) 50%,
    hsl(258deg 73% 18%) 52%,
    hsl(260deg 75% 20%) 55%,
    hsl(262deg 77% 22%) 63%,
    hsl(263deg 80% 24%) 65%,
    hsl(265deg 84% 26%) 66%,
    hsl(267deg 88% 27%) 68%,
    hsl(269deg 93% 29%) 69%,
    hsl(271deg 100% 30%) 80%
  );
}

.bannerContent {
  margin: 33vh auto auto 10vw;
  text-align: left;
}

.bannerContent h1 {
  font-size: max(2.6vw, 30px);
  font-family: "League Spartan", sans-serif;
}

.bannerContent p {
  font-size: max(1vw, 18px);
}

.bannerAbout button {
  border: none;
  background-image: linear-gradient(90deg, rgb(252, 76, 49), rgb(255, 176, 91));
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding: 15px 30px;
  transition: 400ms;
  transform: scale(1.05);
  cursor: pointer;
  margin: 30px 40px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
}

.bannerAbout button:hover {
  transform: scale(1);
  translate: 0;
  box-shadow: 0 0 10px 0 rgba(253, 149, 107, 0.3);
}

@media (max-width: 800px) {
  .bannerContent {
    text-align: center;
    margin: 33vh auto;
  }

  .bannerContent p {
    margin: auto 1vw;
  }
}
