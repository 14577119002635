/* NavBar */
#navBarContainer {
  position: absolute;
  right: 0;
  margin-right: 5vw;
  z-index: 1;
}

#navBarContainer ul {
  display: flex;
  flex-direction: row;
}

#navBarContainer ul li {
  list-style: none;
  margin-left: 50px;
}

#navBarContainer ul li p {
  color: white;
  font-family: "League Spartan", sans-serif;
  font-weight: 700;
  font-size: clamp(22px, 1.5vw, 30px);
  transition: color 300ms;
  cursor: pointer;
  padding: 0;
}

#navBarContainer ul li p:hover {
  color: rgb(255, 113, 91);
}

/* Icons */
.bannerMediaLinks {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 5vh;
  right: 20px;
}

.bannerIcon {
  width: clamp(45px, 3.8vw, 65px);
  height: clamp(45px, 3.8vw, 65px);
  margin: 3vh 1.5vw;
  cursor: pointer;
  color: rgb(175, 175, 175);
  transition: 200ms;
}

.bannerMediaLinks a:hover .bannerIcon {
  color: rgb(255, 113, 91);
}

.bannerMediaLinks a p {
  color: white;
  text-align: center;
  margin: 0;
  translate: 0 30px;
  opacity: 0;
  transition: 300ms;
}

.bannerMediaLinks a:hover p {
  translate: 0 10px;
  opacity: 1;
}

@media (max-width: 800px) {
  .bannerMediaLinks {
    flex-direction: row;
    right: auto;
    width: 100%;
    justify-content: space-around;
  }
}
