.contactAnimationMoving {
  animation: movingContact 9s infinite;
  margin: auto auto auto 0;
}

.contactAnimationMoving img {
  width: 80px;
  height: 80px;
  cursor: pointer;
}

.rotate {
  animation: rotate 2.5s ease;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(35deg);
  }
  70% {
    transform: rotate(-35deg);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes movingContact {
  from {
    transform: translateY(50px);
    opacity: 0;
    scale: 0.8;
  }

  40% {
    transform: translateY(-10px);
    opacity: 1;
    scale: 1;
  }

  to {
    transform: translateY(-50px);
    opacity: 0;
  }
}
