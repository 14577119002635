@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;300;500;700&family=Montserrat:wght@300;400;700;900&display=swap");

.klapToolsContainer {
  font-family: "Montserrat", sans-serif;
  text-rendering: optimizeLegibility;
  background-size: contain;
}

.klapToolsContainer .banner {
  background-image: linear-gradient(
    0deg,
    hsl(199deg 100% 46%) 0%,
    hsl(202deg 100% 44%) 8%,
    hsl(204deg 100% 41%) 14%,
    hsl(207deg 100% 39%) 17%,
    hsl(210deg 100% 36%) 21%,
    hsl(213deg 100% 33%) 27%,
    hsl(217deg 100% 30%) 37%,
    hsl(222, 100%, 27%) 53%,
    hsl(229deg 100% 23%) 74%,
    hsl(240deg 100% 20%) 100%
  );
}

.targetGymContainer {
  font-family: "Montserrat", sans-serif;
  text-rendering: optimizeLegibility;
  background-size: contain;
}

.targetGymContainer .banner {
  background-image: linear-gradient(
    0deg,
    hsl(31deg 100% 50%) 0%,
    hsl(30deg 100% 49%) 8%,
    hsl(28deg 100% 48%) 14%,
    hsl(26deg 100% 47%) 17%,
    hsl(25deg 100% 46%) 21%,
    hsl(23deg 100% 44%) 27%,
    hsl(21deg 100% 43%) 37%,
    hsl(19deg 100% 42%) 53%,
    hsl(16deg 99% 41%) 74%,
    hsl(13deg 98% 40%) 100%
  );
}

.targetGymContainer .roles span {
  color: #006400;
  transition: color 200ms;
}

.targetGymContainer .roles p:hover span {
  color: #003d00;
}

.projectContainer {
  margin: auto;
  max-width: 900px;
  /* background-color: aqua; */
}

.projectContainer h1 {
  font-weight: 400;
  line-height: 1.1;
  font-size: 2.5rem;
}

.projectContainer p {
  font-weight: 300;
  font-size: 1.3rem;
  font-family: "League Spartan", sans-serif;
  color: rgb(69, 69, 69);
}

.projectContainer h2 {
  font-weight: 900;
  font-size: 1.4rem;
}

.projectRelevantInfo {
  display: flex;
}

.projectRelevantInfo div {
  width: 250px;
  /* background-color: blueviolet; */
}

.projectImage {
  margin: 40px 0;
  border-radius: 35px;
  width: 900px;
  box-shadow: 0 0 20px 10px rgba(51, 7, 0, 0.405);
  transition: 400ms;
}

/* .projectImage:hover {
  translate: 0 -10px;
  box-shadow: 0 10px 25px 0 rgb(125, 17, 0);
} */

.linkText {
  color: rgb(255, 113, 91) !important;
  font-weight: 500 !important;
  width: fit-content;
}

.projectRelevantInfo a {
  display: block;
  width: min-content;
}

.banner {
  position: relative;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.banner h1,
.banner p {
  color: white;
}

.banner h1 {
  font-size: 4rem;
  font-family: "League Spartan", sans-serif;
  font-weight: 700;
  margin: 0;
}

.roles {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
}

.roles p,
.roles span {
  text-transform: uppercase;
  font-size: 0.93rem;
  font-weight: bold;
  cursor: pointer;
}

.klapToolsContainer .roles span {
  color: #f39237;
  transition: color 200ms;
}

.klapToolsContainer .roles p:hover span {
  color: #e7770d;
}

.banner .roles .left {
  position: absolute;
  left: calc(16% - 120px);
}

.banner .roles .center {
  position: absolute;
  left: calc(50% - 120px);
}

.banner .roles .right {
  position: absolute;
  right: calc(16% - 60px);
}

.targetGymContainer #navBarContainer ul p:hover {
  color: #003d00 !important;
}

.klapToolsContainer #navBarContainer ul li p:hover {
  color: rgb(255, 113, 91) !important;
}

@media (max-width: 800px) {
  .roles {
    height: 70px;
    flex-direction: column;
    text-align: center;
    margin-bottom: 20px;
  }

  .banner .roles .left {
    position: static;
  }

  .banner .roles .center {
    position: static;
  }

  .banner .roles .right {
    position: static;
  }

  .roles p,
  .roles span {
    margin: 0;
    padding: 0;
  }

  .projectImage {
    width: 90%;
    margin: 40px auto;
  }

  .projectContainer {
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
